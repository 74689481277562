<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    />
    <b10-page-content>
      <b10-view-summary
        :item="formattedItem"
        :showing.sync="showingDialogs.moreInfo"
        :loading-data="loadingData"
        :show-alerts="!routeFromBrowserHistory"
        :more-info-in-dialog="false"
      >
        <template slot="extraSubtitle">
          <extra-subtitle
            v-if="formattedItem.fechaDesinstalacion"
            :fecha-desinstalacion="formattedItem.fechaDesinstalacion"
          />
        </template>
      </b10-view-summary>
      <b10-fab-button
        v-if="hasEditPerm($route.meta.permission.idobjeto)"
        :icon="$vuetify.icons.values.edit"
        @click="clickEdit"
      />
    </b10-page-content>
  </b10-base>
</template>

<script>
import _ from '@/utils/lodash'
import { viewPageMixin } from '@/mixins/viewPageMixin'
import Data from './MaterialSistemaViewData'
import ExtraSubtitle from '../components/ExtraSubtitle'

export default {
  components: {
    ExtraSubtitle
  },
  mixins: [viewPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  props: {
    editRoute: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      moreInfoRows: [],
      showingDialogs: {
        moreInfo: false,
      },
    }
  },
  computed: {
    formattedItem () {
      if (!_.isEmpty(this.item.dataset)) {
        const item = _.cloneDeep(this.item.dataset)
        item.id = item.idmaterialsistema
        item.title = this.title
        item.subtitle = this.subtitle
        item.badge = this.badge
        item.moreInfo = this.buildMoreInfo(this.item.metadata)
        item.fechaDesinstalacion = item.fdesinstalacion
        item.alerts = []
        if (item.fdesinstalacion) {
          item.alerts.push({
            value: `Material DENSINSTALADO el ${this.$options.filters.shortDate(item.fdesinstalacion)}`,
            type: 'warning'
          })
        }
        return item
      } else {
        return {}
      }
    },
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      await this.loadItem()
      this.moreInfoRows = await this.$online.materialSistema.fichaTecnica(this.item.dataset)
      this.title = this.$online.materialSistema.title(this.item.dataset)
      this.subtitle = this.$online.materialSistema.subtitle(this.item.dataset)
      this.badge = this.$online.materialSistema.badge(this.item.dataset)
    },
    async loadItem () {
      this.loadingData = true
      try {
        const [dataset, metadata] = await Data.selectMaterialSistema(
          this, this.routeParams.idmaterial_sistema
        )
        if (dataset) {
          this.$set(this.item, 'dataset', dataset)
          this.$set(this.item, 'metadata', metadata)
        } else {
          this.itemNotFound()
        }
      } finally {
        this.loadingData = false
      }
    },
    async clickEdit () {
      this.$appRouter.push({
        name: this.editRoute,
        params: {
          idmaterial_sistema: this.routeParams.idmaterial_sistema
        },
      })
    },
  },
}
</script>
