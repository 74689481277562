import { APIFilter } from '@/utils/api'

export default {
  async selectMaterialSistema (Vue, idmaterialSistema) {
    const apiFilter = new APIFilter()
    apiFilter.addExact('idmaterial_sistema', idmaterialSistema)
    const resp = await Vue.$api.call('materialSistema.select', { filter: apiFilter })
    return [resp.data.result.dataset[0], resp.data.result.metadata]
  },
}
